<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Your Free eBook is Just One Step Away!</a>
        

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                
            </form>
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">

                <div class="form-group first">
                    <label for="username" class="required">Name</label>
                    <input name="username" type="text" class="form-control" v-model="username" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="email" class="required">Email</label> <span class="mb-4 " style="margin-left: 52%;"></span>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="phone" class="required">Phone(+91)</label>
                    <input name="phone" type="phone" class="form-control" v-model="phone" placeholder="" id="" required>

                </div>
                <!-- <div class="form-group last mb-3">
                    <label for="phone">Remarks</label>
                    <textarea name="remark"  class="form-control" v-model="remark" placeholder="" id=""  textarea/>

                </div> -->
                <input v-on:click="Submit" type="submit" value="Get your free eBook now!" class="btn btn-common">
               

            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/eBook.png" /></div>
            </div>
        </div>
    </div>
   
</template>

<script >
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 
export default {
        name: 'QuickReg',
        data() {
            return {
                username: '',
                email: '',
                phone: '',
            }
        },
        methods: {
            async Submit() {
				axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
				axios.defaults.headers.post['Accept'] = 'application/json';
                const submit_data = {
                    name: this.username,
                    email: this.email,
                    phone: this.phone,
                    
                }
               
                let result = await axios.post("/app/EbookSalesGrowth/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", submit_data);
                if (result.data.status == true) {
                    window.open('https://api.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    window.open('https://web.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    this.$router.push('/Thanks');
                } else {
                    alert(result.data.message);
                }
                
            }
        }
    }
</script>